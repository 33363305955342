import React, { useEffect } from 'react'

import { scrollToID, updatePageTitle, updatePageDescription } from '../components/Helpers'



function Contact() {

    useEffect(() => {
        updatePageTitle("Contact")
        updatePageDescription()
        scrollToID("contact-section")
    }, []);

    return (
        <>

        </>
    )
}

export default Contact