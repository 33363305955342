import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from '../src/App';

import './assets/css/globalStyle.css'
import './assets/css/style.css'
import './assets/css/siteStyle.css'


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter><App tab="home" /></BrowserRouter>);