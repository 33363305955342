import React, { useState, useEffect } from "react"
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useLocation } from "react-router-dom"
import axios from "axios"

import { updatePageTitle, updatePageDescription, LoadingPage } from '../components/Helpers'

function Pages() {

  const [isLoaded, setIsLoaded] = useState(false);
  const [PageContent, setPageContent] = useState([]);
  const location = useLocation();

  useEffect(() => {
    GetPage()
    updatePageTitle()
    updatePageDescription()

    async function GetPage() {
      await axios.get("https://cdn.sbhelper.com/sbhelper/pages" + location.pathname + '.htm')
        .then(res => {
          setIsLoaded(true);
          const result = res.data;
          setPageContent(result);
        })
        .catch(error => {
          setIsLoaded(true);
          updatePageTitle("Page not found")
          setPageContent("<h1>404</h1><br/><div>There was an error loading the page. Perhaps an endpoint is temporarily down, the page does not exist, or the page has been moved.</div>")
        })

      return
    }

  }, [location.pathname])

  if (isLoaded === false) {
    return (
      <LoadingPage />
    )
  }

  return (
    <>
      <br /><br />
      <Container>
        <Row>

          <div dangerouslySetInnerHTML={{ __html: PageContent }} />

        </Row>
      </Container>
    </>
  )
}

export default Pages