import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { Button, Container, Col, Row } from 'react-bootstrap'

import { MdOutlineEmail } from "react-icons/md"
import { IoOpenOutline } from "react-icons/io5"
import { MdOutlinePermPhoneMsg } from "react-icons/md"
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FcCellPhone } from "react-icons/fc";
import { FcSms } from "react-icons/fc";

import { scrollToID } from "../components/Helpers"
import ContactForm from '../components/ContactForm'



function Footer() {

    const EmailTo = ({ emailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = emailto;
                    e.preventDefault();
                }}
            >
                <MdOutlineEmail /> {label} <IoOpenOutline />
            </Link>
        );
    };

    const CallTo = ({ callto, label }) => {
        return (
            <Button
                to='#'
                onClick={(e) => {
                    window.location.href = callto;
                    e.preventDefault();
                }}
            >
                <FcCellPhone /> {label} <IoOpenOutline />
            </Button>
        );
    };

    const SMSTo = ({ smsto, label }) => {
        return (
            <Button
                to='#'
                onClick={(e) => {
                    window.location.href = smsto;
                    e.preventDefault();
                }}
            >
                <FcSms /> {label} <IoOpenOutline />
            </Button>
        );
    };

    return (
        <>
            <Container fluid className="full-background-container bg-color-accent-dark">
                <Row>
                    <Col md={4} sm={12} xs={12} >
                        <h2 id="contact-section" className="section">Contact</h2>

                        <div><EmailTo emailto="mailto:support@sbhelper.com" label="support@sbhelper.com" /></div>
                        <br />
                        <div><MdOutlinePermPhoneMsg /> (541) 579-8983</div>
                        <div>
                            <CallTo callto="tel:+15415798983" label="Call" />
                            <SMSTo smsto="sms:+15415798983" label="Text" />
                        </div>
                        <br />
                        <div className="social-media-container">
                            <Button className="link pointer" href="https://www.facebook.com/sbHelperLLC" aria-label="Facebook"><FaFacebook /></Button>
                            <Button className="link pointer" href="https://www.instagram.com/sbhelperllc" aria-label="Instagram"><FaInstagram /></Button>
                        </div>
                        <br />
                    </Col>
                    <Col md={8} sm={12} xs={12} >
                        <ContactForm />
                        <br />
                    </Col>

                </Row>
            </Container>
            <div className="full-background-container bg-color-header footerContainer">
                <br />
                <Container fluid>
                    <Row>
                        <Col md={4} sm={6} xs={12}>
                            <h4>Company</h4>
                            <div className="footer-wrapper">
                                <div><Link className="link pointer" to="/legal/privacy-policy" onClick={() => { scrollToID("page-container") }}>Privacy Policy</Link></div>
                                <div><Link className="link pointer" to="/legal/terms-and-conditions" onClick={() => { scrollToID("page-container") }}>Terms and Conditions</Link></div>
                                <br />
                                <div>Based in Roseburg, OR</div>
                                <div>Servicing Douglas County</div>

                            </div>

                            <br />
                        </Col>
                        <Col md={4} sm={6} xs={12} >
                            <div className="footer-wrapper">


                            </div>
                            <br />
                        </Col>

                        <Col md={4} sm={6} xs={12}>
                            <h4>Business Hours</h4>
                            <div className="footer-wrapper">
                                <div>Mon-Fri: 9am - 5pm</div>
                                <div>Sat: 10am - 5pm</div>
                                <div>Sun: closed</div>
                            </div>
                            <br />
                        </Col>
                        <Col md={4} sm={6} xs={12}>

                            <div className="footer-wrapper">


                            </div>
                            <br />
                        </Col>
                    </Row>
                </Container>
                <br /><br />
                <Container fluid align="center">
                    <Row>
                        <Col xs={12}>
                            <div className="copyright">
                                &copy; 2021 - {new Date().getFullYear()} <em>sbHelper, LLC</em>. All rights reserved.
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={8} align="left">

                        </Col>
                        <Col xs={4} align="right">
                            <div className="copyright">
                                <small>v{localStorage.getItem('frontendCurrentVersion')}</small>
                            </div>
                        </Col>
                    </Row>
                    <br /><br />
                </Container>
            </div>
        </>
    )
}

export default Footer




