import React, { useState, useEffect, Component } from 'react'
import { toast } from 'react-toastify';
import axios from "axios"
import Alert from 'react-bootstrap/Alert';
import { Routes, Route, useLocation } from "react-router-dom"
import { MdNotificationsNone, MdOutlineNotificationImportant } from 'react-icons/md'

import { Link } from "react-router-dom"




export function PulsePost(formData) {
  var pulseCount = 0;
  var pulseCount2 = 0;

  axios.defaults.withCredentials = true

  const pulsePost = async () => {

    let algoWallet = formData['algoWallet'];

    if (localStorage.getItem('authenticated') == 0 && JSON.parse(localStorage.getItem("pageIncludes")).sWallet !== algoWallet) { localStorage.setItem('authenticated', '2') }
    localStorage.setItem('algoWallet', algoWallet)

    let response = await axios('https://api.freckletoken.com/pulse', {
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {

        if (response.status === 200) {
          const rData = response.data;

          if (!rData) { localStorage.clear(); }


          if (rData.pageIncludes === 'algoWallet mismatch!') {
            localStorage.clear()
            //alert('Fatal Error! You have compromised the integrity of this app - your wallet has been blacklisted.')
            return (window.location.reload())
          }

          if (rData.pageIncludes === 'reload') {

            localStorage.clear()

            setInterval(() => {
              return (window.location.reload())
            }, 5000)
            //alert('Whacky Error! Be sure to run this app in one tab only.')
            //return(window.location.reload())
          }

          localStorage.setItem('serverConnections', rData.serverConnections);
          localStorage.setItem('apiStatus', rData.apiStatus);
          localStorage.setItem('serverMessage', rData.serverMessage);
          localStorage.setItem('nodeStatus', rData.nodeStatus);
          localStorage.setItem('pageIncludes', rData.pageIncludes);
          localStorage.setItem('responseMessages', rData.responseMessages);
          localStorage.setItem('authenticated', rData.authenticated);
          localStorage.setItem('tempCode', rData.tempCode);
          localStorage.setItem('profileInformation', JSON.stringify(rData.profileInformation));
          //set node from profile information

          try {
            if (localStorage.getItem('frontendCurrentVersion') && rData.frontendCurrentVersion !== localStorage.getItem('frontendCurrentVersion')) {
              alert("App Update!")
              return window.location.reload()
            }

          } catch {
            return window.location.reload()
          }


          return response
        } else {
          localStorage.clear();
          return ''
        }


      })
      .catch(function (error) {
        localStorage.setItem('apiStatus', 'Restricted')
        setInterval(() => {
          EndAPISession();
          localStorage.clear();
          localStorage.setItem('apiStatus', 'Restricted')
          return (window.location.reload())
        }, 10000)
      })
  };


  pulsePost(formData)


  if (pulseCount === 0 || pulseCount >= 10) {
    GetAccountAssetList()
    pulseCount = 1;
  }

  if (pulseCount2 === 0 || pulseCount2 >= 30) {
    GetAccountAssetDetailsList()
    pulseCount2 = 1;
  }

  pulseCount = pulseCount + 1;
  pulseCount2 = pulseCount2 + 1;

  return ''

}


export async function generalPost(formData, endpoint) {

  axios.defaults.withCredentials = true


  let response = await axios('https://api.sbhelper.com/' + endpoint, {
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'application/json' }
  })
    .then(function (response) {

      if (response.status === 200) {
        const rData = response.data;
        let message = rData.message.message
        let type = rData.message.type
        //SetTempResponseMessage(message, type)
        return rData;
      } else {
        localStorage.clear();
      }
    })
    .catch(function (error) {
      //SetTempResponseMessage(error, "danger")
      return { "message": error, "type": "error" }
    })

  return response;
}



export function StartAPISession(algoWallet) {

  localStorage.setItem('algoWallet', algoWallet)

  if (localStorage.getItem('authenticated') == 0 && JSON.parse(localStorage.getItem("pageIncludes")).sWallet !== algoWallet) { localStorage.setItem('authenticated', '2') }

  const formData = { 'algoWallet': algoWallet };

  this.PulsePost(formData)

  return ''


}

export function EndAPISession() {
  const peraWallet = new PeraWalletConnect();

  peraWallet.disconnect();

  const formData = {};

  localStorage.setItem('algoWallet', '');

  const sendEndAPISession = async () => {
    axios.defaults.withCredentials = true

    let response = await axios('https://api.freckletoken.com/auth/signout', {
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(function (response) { return window.location.reload() })

  };

  sendEndAPISession()
}



export async function GetAccountAssetDetailsList() {

  let algoWallet = GetWalletConnectAlgoWallet();

  async function GetAccountAssetDetailsListNow(algoWallet) {
    await axios.get("https://api.freckletoken.com/wallet/assets?algoWallet=" + algoWallet)
      .then(res => {
        if (res.status === 200) {
          const result = res.data;
          localStorage.setItem('accountAssetListDetails', JSON.stringify(result.pageContent));
        }

      })
      .catch(error => {
        return '';
      });

  }


  if (algoWallet.length === 58) {
    GetAccountAssetDetailsListNow(algoWallet);
  }


  return '';
}


export function LoadingPage() {
  return (<div id="loadingContainer">
    <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
    <div>Loading...</div>
  </div>)
}

export function StandbyPage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>Standby...</div>
      <br />
      <div className="small standbyDelayMsg">If this is taking more then 30 seconds, there might be an error. Try to refresh your page.</div>
    </div>
  </div>
  )
}

export function RestrictedPage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>Something went wrong. Perhaps you are clicking too fast or there are too many connections from your location. Please wait about 10 seconds.</div>
      <br />
      <div>If the page doesn't automatically reload, you should refresh the page.</div>
      <br />
      <div>If this continues, you might be doing something wrong or there might be an app error.</div>
    </div>
  </div>
  )
}

export function MaintenancePage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>Maintenance...</div>
    </div>
  </div>
  )
}

export function IdlePage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>You have been inactive. To continue, you must perform an action - move your mouse, click a button, touch your screen, etc.</div>
    </div>
  </div>
  )
}


export function CreatingSession() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>Connecting to Freckle and validating your session...</div>
    </div>
  </div>
  )
}


export function ServerMessage({ Message }) {

  if (Message !== '') {
    return (
      <>
        <div className="serverMessageSpacing"></div>
        <div className="serverMessage">
          <Alert variant="warning">
            {Message}
          </Alert>
        </div>
      </>
    )
  }

  return ''

}


export function SetTempResponseMessage(Message, Type) {

  localStorage.setItem('tempResponseMessage', '{"message":"' + Message + '","type":"' + Type + '","time":' + Date.now() + '}')

  return ''
}

export function TempResponseMessage() {

  try {
    if (localStorage.getItem('tempResponseMessage')) {

      if ((Date.now() - JSON.parse(localStorage.getItem('tempResponseMessage')).time) >= '8000') { localStorage.setItem('tempResponseMessage', ''); return ''; }

      function ClearMessage() {
        localStorage.setItem('tempResponseMessage', '');
        return '';
      }

      return (
        <>
          <div className="tempResponseMessageSpacing"></div>
          <div className="tempResponseMessage">
            <Alert variant={JSON.parse(localStorage.getItem('tempResponseMessage')).type} onClose={() => ClearMessage()} dismissible onClick={() => { ClearMessage() }}>
              {JSON.parse(localStorage.getItem('tempResponseMessage')).message}
            </Alert>
          </div>
        </>
      )
    }
  } catch (Exception) { return 'There was an error.' }

  return ''

}

export function ResponseMessage(response) {

  try {
    if (response.message && response.type) {
      let message = response.message
      let type = response.type

      toast[type](message, { theme: "colored" })

    }

  } catch {
    toast.error("Error processing response notification")
  }


}

export function AccountNotification() {
  const location = useLocation();

  if (!IsSignedIn()) { return }
  if (1 == 0) { return <Link className="quickActionBar_Button" to={location.pathname}><MdOutlineNotificationImportant /></Link> }

  return <Link className="quickActionBar_Button" to={location.pathname}><MdNotificationsNone /></Link>
}

export function AccountMessages() {
  const location = useLocation();

  if (!IsSignedIn()) { return }
  if (1 == 0) { return <Link className="quickActionBar_Button" to={location.pathname}><BiMessageRoundedDots /></Link> }

  return <Link className="quickActionBar_Button" to={location.pathname}><BiMessageRounded /></Link>
}

export function AccountMissionMap() {
  const location = useLocation();

  if (!IsSignedIn()) { return }

  return <Link className="quickActionBar_Button" to={location.pathname}><BiMapAlt /></Link>
}