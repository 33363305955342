import React from 'react'

function AboutPartial() {
  return (
    <>
      <br />
      <div className="about-container">
        <span className="bold">Managed Service Provider</span> with a dedication to support and help grow small businesses, by managing their technology needs.
        Including computer repair, diagnostics, network and system management, website development, scripting, security cameras, and various personal device support.
        We bring a wide range of technical expertise, with more than 20 years in the tech field.
        Our platform and services easily extend to multi-computer families and non-profit organizations.
      </div>
    </>
  )
}

export default AboutPartial