import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import AboutPartial from '../partials/AboutPartial'
import { updatePageTitle, updatePageDescription } from '../components/Helpers'


function Home() {

  useEffect(() => {
    updatePageTitle("small business Helper")
    updatePageDescription()
  }, []);

  return (
    <>


      <Container fluid>
        <br /><br />
        <Row className="page-width">
          <h1 className="section">We are:</h1>
          <Col lg={4} md={12} xs={12}>
            <div className="card">
              <div className="card-title">sbHelper Platform</div>
              <div className="card-image"><img alt="sbHelper Platform" title="sbHelper Platform" src="https://cdn.sbhelper.com/sbhelper/images/platform.webp" /></div>
              <div className="card-content">
                <div>Designed to streamline operations, enhance productivity, and meet the diverse needs of small businesses. We bring favorite tools and solutions to a single platform.</div>
              </div>
              <div className="card-quote"></div>
            </div>
            <br />
          </Col>

          <Col lg={4} md={12} xs={12}>
            <div className="card">
              <div className="card-title">Hosting</div>
              <div className="card-image"><img alt="Hosting" title="Hosting" src="https://cdn.sbhelper.com/sbhelper/images/hosting.webp" /></div>
              <div className="card-content">
                <div>From domain registration and website hosting to email services and SSL certificates, we manage it all. Our comprehensive hosting solutions ensure your website runs smoothly, securely, and efficiently. We will take care of the technical details, so you can focus on building your online presence and growing your business.</div>
              </div>
              <div className="card-quote"></div>
            </div>
            <br />
          </Col>

          <Col lg={4} md={12} xs={12}>
            <div className="card">
              <div className="card-title">Network and Systems Administration</div>
              <div className="card-image"><img alt="Network Diagram" title="Network Diagram" src="https://cdn.sbhelper.com/sbhelper/images/diagrams/network-diagram.webp" /></div>
              <div className="card-content">We are ready to be the backbone of your IT infrastructure, providing expert network and systems administration. We will manage and maintain your network hardware, software, and overall systems to ensure they run smoothly and securely.</div>
              <div className="card-quote"></div>
            </div>
            <br />
          </Col>
        </Row>
        <Row className="page-width">
          <Col align="left" xs={12}>
            <AboutPartial />
          </Col>
        </Row>
      </Container>

      <br /><br />
      <Container fluid className="full-background-container bg-color-accent-darker">
        <div className="page-width">
          <h2 className="section">here for you</h2>
          <div className="card">

            <Row>

              <Col md={6} xs={12}>

                <div className="card-title" align="left">Remote Support</div>
                <div className="card-image" align="right"><img alt="Remote Support" title="Remote Support" src="https://cdn.sbhelper.com/sbhelper/images/remote-support.webp" /></div>
                <br />
              </Col>
              <Col align="center" md={6} xs={12}>

                <div className="card-title" align="left">In-Person Support</div>
                <div className="card-image" align="right"><img alt="In Person Support" title="In-Person Support" src="https://cdn.sbhelper.com/sbhelper/images/in-person-support.webp" /></div>
                <br />
              </Col>
            </Row>
            <Row>
              <Col align="center" md={6} xs={12}>

                <div className="card-title" align="left">Disaster Recovery <br />and Planning</div>
                <div className="card-image" align="right"><img alt="Disaster Recovery" title="Disaster Recovery" src="https://cdn.sbhelper.com/sbhelper/images/disaster-recovery.webp" /></div>
                <br />
              </Col>
              <Col align="center" md={6} xs={12}>

                <div className="card-title" align="left">Cybersecurity</div>
                <div className="card-image" align="right"><img alt="Cybersecurity" title="Cybersecurity" src="https://cdn.sbhelper.com/sbhelper/images/cybersecurity.webp" /></div>
                <br />


              </Col>

            </Row>
          </div>
          <br />
          <h2 className="section">and more.</h2>
        </div>
      </Container >

    </>
  )
}

export default Home