import "react-toastify/dist/ReactToastify.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { lazy, Suspense, useState, useEffect } from 'react'
import { Link, Routes, Route, useLocation } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import { Container, Col, Row } from 'react-bootstrap'


import Navbar from '../components/Navbar'
import ScrollToTop from '../components/ScrollToTop'
import Footer from './Footer'

//import Profile from '../components/Profile'

import Contact from '../pages/Contact'
import HomePage from '../pages/HomePage'
import Pages from '../pages/Pages'
import Pricing from '../pages/Pricing'
import TempPage from '../pages/TempPage'

//import TestPage from '../pages/TestPage'
//import Articles from '../pages/Articles'
//import Tools from '../pages/Tools'
//import { BiRefresh } from 'react-icons/bi'




function Theme() {

  const location = useLocation();
  const routesData = [
    { path: '/', component: HomePage },
    { path: '/contact', component: Contact },
    { path: '/pricing', component: Pricing },
    { path: '/temp-page', component: TempPage },
    { path: '/*', component: Pages },
  ];


  const onPrompt = () => {
    // Fire a Modal Prompt

  }



  useEffect(() => {
    let host = window.location.host;

    setInterval(() => {


      if (!host.startsWith('localho')) {
        console.clear();
        console.log("%c!!!STOP!!!", "background: red; color: yellow; font-size: x-large");
        console.log("%cHey listen!", "background: blue; color: white; font-size: large")
        console.log("%cAltering, manipulating, or trying to circumvent code may result in losing your account and/or getting blacklisted. Be sure to use this site and tools as they are intended to be used.", "font-size: medium; font-weight:bold;")

      }

    }, 500);


  }, []);


  return (

    <>
      <Navbar />

      <div id="page-container" className="page-container">
        <Routes >
          {routesData.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}
        </Routes>
      </div>

      <Footer />
      <ScrollToTop />

      <div className="toast-container-x">
        <ToastContainer />
      </div>

    </>

  )
}



export default Theme;

