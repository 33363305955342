import React, { useState, useEffect } from 'react'
import { scrollToTop } from '../components/Helpers'

import { FaArrowUp } from "react-icons/fa"

function ScrollToTop() {

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            {scrollPosition > 500 && (
                <div className="scroll-to-top-container">
                    <div className="scroll-to-top" onClick={() => { scrollToTop() }}>
                        <FaArrowUp />
                    </div>
                </div>
            )}

        </>
    )
}

export default ScrollToTop;