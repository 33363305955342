import React, { useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { scrollToID } from '../components/Helpers'

function TempPage() {

    return (
        <>

            <br /><br />

        </>
    )
}

export default TempPage