import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import axios from "axios"


export function LoadingPage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/sbhelper/images/site-logo-small.webp" height="auto" width="150px"
        alt="sbHelper Logo" className="imageSpin" />
      <div>Loading...</div>
    </div>
  </div>
  )
}

export function StandbyPage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/sbhelper/images/site-logo-small.webp" height="auto" width="150px"
        alt="sbHelper Logo" className="imageSpin" />
      <div>Standby...</div>
    </div>
  </div>
  )
}

export function scrollToTop() {

  const element = document.getElementById('topofpageRef');
  if (element) {
    window.scrollTo({
      top: element.offsetTop,
      behavior: 'smooth',
    });
  };

}

export function scrollToID(refID) {
  document.getElementById(refID).scrollIntoView({ block: "start", behavior: "smooth" })
}


export function updatePageTitle(page_title) {
  const sitename = "sbHelper";
  const basedomain = document.location.href
  const original_url_path = location.pathname
  var canonical_url = basedomain

  if (original_url_path === '/') {
    canonical_url = canonical_url.substring(0, canonical_url.length - 1)
  }


  if (page_title) {
    var page_title = page_title + " | " + sitename
  } else {
    var generated_title = ""
    var urlPath = location.pathname

    if (urlPath == "/business-card/sbhelper") {
      generated_title = "Business Card"
    }
    else if (urlPath == "/business-card") {
      generated_title = "Digital Business Card Service"
    }
    else if (urlPath == "/legal/privacy-policy") {
      generated_title = "Privacy Policy"
    }

    else {
      urlPath = urlPath.replace("/", "")
      urlPath = urlPath.split("/")

      urlPath.map((path) => (
        generated_title = generated_title + " " + path.replace("-", " ")
      ))
    }



    var page_title = generated_title + " | " + sitename
  }

  document.getElementById("page-canonical").setAttribute("href", canonical_url);
  document.getElementById("page-title").innerHTML = page_title;
}


export function updatePageDescription(page_description) {
  var default_description = "Elevate your small business with our managed services at sbHelper. Computer repair to server management, we have over 20 years of experience to support you."

  if (page_description) {
    var page_description = page_description
  } else {
    var urlPath = location.pathname
    var page_description = default_description

    if (urlPath == "/business-card/sbhelper") {
      page_description = "sbHelper's QR Code business card landing page."
    }
    else if (urlPath == "/business-card") {
      page_description = "QR Code landing page for your business card. Use a simple fixed URL with a page you can keep up to date."
    }

  }

  document.querySelector('meta[name="description"]').setAttribute("content", page_description);
}
