import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FaCheck } from "react-icons/fa"

import { scrollToID, updatePageTitle, updatePageDescription } from '../components/Helpers'



function Pricing() {

    useEffect(() => {
        updatePageTitle("Pricing")
        updatePageDescription()
    }, []);

    return (
        <>
            <br /><br />

            <Container>

                <Row>
                    <h1>Pricing</h1>
                </Row>

            </Container>
            <br />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div id="pricing-monthly-subscription" title="Monthly Subscription" className="card">
                            <div className="card-title">Monthly Subscription</div>
                            <div className="card-content">
                                <h2 align="left"><span className="bold">$40</span></h2>
                                <br />
                                <div className="bold">Full Service Including:</div>
                                <Row>
                                    <Col xs={12}>
                                        <div className="list-item"><FaCheck /> Hosting (Website, Email, etc.)</div>
                                        <div className="list-item"><FaCheck /> Platform Tools</div>
                                        <div className="list-item"><FaCheck /> Network Management</div>
                                        <div className="list-item"><FaCheck /> System Management</div>
                                        <div className="list-item"><FaCheck /> Cybersecurity Monitoring</div>
                                        <div className="list-item"><FaCheck /> Proactive Response</div>
                                        <div className="list-item"><FaCheck /> Priority Support</div>
                                        <div className="list-item"><FaCheck /> Monthly Billing</div>
                                        <div className="list-item"><FaCheck /> Reduced Rates</div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <br />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div id="pricing-general-computer-repair" title="Computer Repair" className="card">
                            <div className="card-title">Computer Repair, Recovery, and Upgrades</div>
                            <div className="card-content">
                                <Row>
                                    <div className="bold">Flat Rate*</div>
                                    <Col md={5} xs={8}>
                                        <div className="list-item dotted-filler-after">Non-Subscribers</div>
                                        <div className="list-item dotted-filler-after">Subscribers***</div>
                                        <div className="list-item dotted-filler-after">Diagnostics</div>
                                    </Col>
                                    <Col md={4} xs={4}>
                                        <div className="list-item">$70**</div>
                                        <div className="list-item">$60**</div>
                                        <div className="list-item">Free</div>
                                    </Col>
                                    <Col md={4} xs={0}>
                                    </Col>

                                </Row>
                                <br />
                                <Row>
                                    <div className="bold">On-Site or Pickup and Delivery</div>
                                    <Col md={5} xs={8}>
                                        <div className="list-item dotted-filler-after">up to 10 miles</div>
                                        <div className="list-item dotted-filler-after">more than 10 miles</div>
                                    </Col>
                                    <Col md={4} xs={4}>
                                        <div className="list-item">Free</div>
                                        <div className="list-item">$0.50/mile</div>
                                    </Col>
                                    <Col md={4} xs={0}>
                                    </Col>

                                </Row>
                                <div className="smallText">*per system, per instance</div>
                                <div className="smallText">**plus the cost of parts</div>
                                <div className="smallText">***hourly rates applicable, will apply flat rate if lesser</div>
                                <br />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <br />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div id="pricing-website-email" title="Website and Email" className="card">
                            <div className="card-title">Website and Email*</div>
                            <h2 align="left"><span className="bold">$600**</span></h2>
                            <br />
                            <div className="bold">Starter Website and Email Including:</div>
                            <Row>
                                <Col xs={12}>
                                    <div className="list-item"><FaCheck /> Email Accounts</div>
                                    <div className="list-item"><FaCheck /> Site Design and Development</div>
                                    <div className="list-item"><FaCheck /> Integrated Contact Form</div>
                                    <div className="list-item"><FaCheck /> SSL</div>
                                    <div className="list-item"><FaCheck /> Backup</div>
                                    <div className="list-item"><FaCheck /> Search Engine Optimization</div>
                                    <div className="list-item"><FaCheck /> Flexible and Customizable</div>
                                </Col>
                                <div className="smallText">*One time fee - <span className="link link-accent-dark pointer" onClick={() => { scrollToID("pricing-monthly-subscription") }} >Monthly Subscription</span> is required</div>
                                <div className="smallText">**per website and/or per domain</div>
                                <div className="smallText">Custom Development Pricing: see Advanced and Custom Projects below</div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div id="pricing-learning" title="Computer Training - Basic" className="card">
                            <div className="card-title">Computer Training: Basic</div>
                            <h2 align="left"><span className="bold">$30/hr</span></h2>
                            <br />
                            <Row>
                                <Col xs={12}>
                                    <div className="list-item"><FaCheck /> 1-on-1</div>
                                    <div className="list-item"><FaCheck /> Demonstration</div>
                                    <div className="list-item"><FaCheck /> Activity</div>
                                    <div className="list-item"><FaCheck /> Question and Answer</div>
                                </Col>
                            </Row>

                            <Row>
                                <div className="bold">Travel - On-Site, Office, Home, etc.</div>
                                <Col md={5} xs={8}>
                                    <div className="list-item dotted-filler-after">up to 10 miles</div>
                                    <div className="list-item dotted-filler-after">more than 10 miles</div>
                                </Col>
                                <Col md={4} xs={4}>
                                    <div className="list-item">Free</div>
                                    <div className="list-item">$0.50/mile</div>
                                </Col>
                                <Col md={4} xs={0}>
                                </Col>

                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <br />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div id="pricing-custom-development" title="Custom Projects" className="card">
                            <div className="card-title">Advanced and Custom Projects</div>
                            <Row>
                                <div className="bold">Non-Subscribers</div>
                                <Col md={5} xs={8}>
                                    <div className="list-item dotted-filler-after">less than 10 hours</div>
                                    <div className="list-item dotted-filler-after">10 to 40 hours</div>
                                    <div className="list-item dotted-filler-after">more than 40 hours</div>
                                </Col>
                                <Col md={4} xs={4}>
                                    <div className="list-item">$110/hr</div>
                                    <div className="list-item">$90/hr</div>
                                    <div className="list-item">$80/hr</div>
                                </Col>
                                <Col md={4} xs={0}>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <div className="bold">Subscribers</div>
                                <Col md={5} xs={8}>
                                    <div className="list-item dotted-filler-after">less than 10 hours</div>
                                    <div className="list-item dotted-filler-after">10 to 40 hours</div>
                                    <div className="list-item dotted-filler-after">more than 40 hours</div>
                                </Col>
                                <Col md={4} xs={4}>
                                    <div className="list-item">$70/hr</div>
                                    <div className="list-item">$60/hr</div>
                                    <div className="list-item">$50/hr</div>
                                </Col>
                                <Col md={4} xs={0}>
                                </Col>
                            </Row>
                            <div className="smallText">Basic guide to determine project quotes. 1 hour minimum.</div>
                            <br />
                        </div>
                    </Col>
                </Row>
            </Container>
            <br /><br />


        </>
    )
}

export default Pricing