import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Offcanvas from 'react-bootstrap/Offcanvas';
import { scrollToID } from './Helpers'

import { IoCloseSharp, IoHomeSharp } from "react-icons/io5";
import { IoIosPricetags } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineContactPhone } from "react-icons/md";
function Navbar() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="navbar-container">
                <div className="navbar-x">
                    <div className="companyLogoNav-container">
                        <Link to="/" aria-label="Logo" onClick={() => { scrollToID("topofpageRef") }}><img id="companyLogoNav" className="companyLogoNav pointer" alt="logo" title="Logo" src="https://cdn.sbhelper.com/sbhelper/images/site-logo-small.webp" /></Link>
                    </div>
                    <div className="navbar-button-container">
                        <Link className='btn btn-primary link' aria-label="Home" to="/" onClick={() => { scrollToID("topofpageRef") }}>
                            <IoHomeSharp />
                        </Link>
                        <Link className='btn btn-primary link' aria-label="Pricing" to="/pricing" onClick={() => { scrollToID("topofpageRef") }}>
                            <IoIosPricetags /> Pricing
                        </Link>
                        <Link className='btn btn-primary link' aria-label="Contact" onClick={() => { scrollToID("contact-section") }}>
                            <MdOutlineContactPhone /> Contact
                        </Link>
                        <Link className='btn btn-primary link hamburger-link' aria-label="Menu" onClick={handleShow}>
                            <GiHamburgerMenu />
                        </Link>
                    </div>
                </div >
            </div>




            <Offcanvas className="side-nav" show={show} onHide={handleClose} placement="end" backdrop="static" scroll={false}>
                <Offcanvas.Header>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                    <div className="btn btn-primary close-btn" aria-label="Close" onClick={handleClose}><IoCloseSharp /></div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="side-nav-menu-container">
                        <div>More coming soon...</div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
}

export default Navbar;